// src/pages/UserPage.js
import React, { useState, useEffect, useRef } from 'react';
import './UserPage.css';

const UserPage = () => {
  const [notifications, setNotifications] = useState([]);
  const ws = useRef(null);

  useEffect(() => {
    // Inicializa WebSocket al cargar la página
    ws.current = new WebSocket('ws://45.236.131.34:8080');

    ws.current.onopen = () => {
      console.log('Conectado al servidor WebSocket');
      ws.current.send(JSON.stringify({ type: 'register', client: 'react' })); // Registrar cliente
    };

    ws.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === 'notification') {
        setNotifications((prev) => [...prev, data.message]); // Agrega notificación
      }
    };

    ws.current.onclose = () => {
      console.log('Desconectado del servidor WebSocket');
    };

    return () => {
      ws.current.close(); // Cerrar conexión al desmontar
    };
  }, []);

  const sendCommand1 = () => {
    if (ws.current) {
      ws.current.send(JSON.stringify({ type: 'command', command: 'OUT_1' }));
      console.log('Comando enviado: OUT_1');
    }
  };

  const sendCommand2 = () => {
    if (ws.current) {
      ws.current.send(JSON.stringify({ type: 'command', command: 'OUT_2' }));
      console.log('Comando enviado: OUT_2');
    }
  };

  return (
    <div className="user-container">
      <h1>Welcome, User!</h1>
      <p>This is the user dashboard.</p>

      <button
        onClick={sendCommand1}
        className="led-button"
        style={{ padding: '10px 20px', fontSize: '16px', margin: '20px 0' }}
      >
        Enviar pulso 1
      </button>

      <button
        onClick={sendCommand2}
        className="led-button"
        style={{ padding: '10px 20px', fontSize: '16px', margin: '20px 0' }}
      >
        Enviar pulso 2
      </button>


      <h2>Notificaciones:</h2>
      <ul className="notifications-list" style={{ listStyleType: 'none', padding: '0' }}>
        {notifications.map((notification, index) => (
          <li key={index} style={{ background: '#f4f4f4', padding: '10px', margin: '5px 0' }}>
            {notification}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UserPage;
